import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Grid from "../../components/grid"
import Image from "../../components/image"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const Mediaguide = () => {
  const data = useStaticQuery(graphql`
    query MediaguideFrancaisQuery {
      nummern: file(relativePath: { eq: "mediaguide/nummern.jpg" }) {
        ...largeImage
      }
    }
  `)

  return (
    <Layout backdrop="mediaguide">
      <Seo
        title="Audioguide et traductions en français"
        description="ll y a un média-guide pour l'exposition permanente. Il contient une visite audio en français et des traductions en français de tous les textes de l'exposition."
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Media-Guide",
              link: "/mediaguide",
            },
            {
              title: "Français",
              link: "/mediaguide/francais",
            },
          ]}
        />
        <PageTitle>Audioguide et traductions en français</PageTitle>
        <Paragraph drropcap={true}>
          ll y a un média-guide pour l'exposition permanente. Il contient une
          visite audio en français et des traductions en français de tous les
          textes de l'exposition.
        </Paragraph>
        <Paragraph>
          Vous pouvez emprunter le média-guide en tant qu'appareil à la caisse
          du musée ou le télécharger en tant qu'application sur votre portable
          pour pouvoir l'utiliser en dehors du musée. Avec la visite audio vous
          pouvez organiser votre propre parcours à travers l'exposition. Piéce
          par pièce. Ou d'un objet à l'autre.
        </Paragraph>
        <Grid columns={[1, 1, 2]}>
          <Paragraph>
            À côté de tous les textes de l'exposition vous trouvez un symbole
            répétitif avec un numéro alternatif. Si vous tapez le numéro dans le
            média-guide, vous obtiendrez le texte approprié. Si vous le
            préférez, vous pouvez lire aussi les textes anglais pièce par pièce
            d'une manière indépendante.
          </Paragraph>
          <Image
            image={data.nummern.childImageSharp.gatsbyImageData}
            alt="Ein aufgeschlagenes Buch mit Hölderlins Übersetzung der ersten pythischen Ode"
          />
        </Grid>
      </Stack>
    </Layout>
  )
}

export default Mediaguide
